import { createConsumer, logger } from "@rails/actioncable";

logger.enabled = false;

let consumer;
let consumer_check_timer;

function createConsumerwithReconnect() {
  if (typeof consumer !== "undefined") {
    return { consumer, consumer_check_timer };
  }

  consumer = createConsumer();
  // consumer_check_timer = setInterval(() => {
  //   console.log("consumer_check_timer check");
  //   if (!consumer.connection.isActive()) {
  //     console.log("Detect consumer connection lost. Try to re-connect");
  //     consumer.connect();
  //   }
  // }, 6000);

  return { consumer, consumer_check_timer };
}

export { createConsumerwithReconnect, consumer, consumer_check_timer };
