import { createConsumerwithReconnect } from "./consumer";

let UserDevicesChannel;
let callbacks = {};

function createUserDevicesChannel() {
  let { consumer, consumer_check_timer } = createConsumerwithReconnect();
  if (typeof UserDevicesChannel !== "undefined") {
    return UserDevicesChannel;
  }

  UserDevicesChannel = consumer.subscriptions.create(
    { channel: "UserDevicesChannel" },
    {
      on(event, cb) {
        if (typeof callbacks[event] !== "undefined") {
          callbacks[event].push(cb);
        } else {
          callbacks[event] = [cb];
        }
        return cb;
      },

      unsubscribeChannel() {
        this.unsubscribe();
        UserDevicesChannel = undefined;
        callbacks = {};
      },

      cancleOn(event, cb) {
        if (typeof callbacks[event] !== "undefined") {
          callbacks[event] = callbacks[event].filter((cb_object) => cb_object === cb);
        }
      },

      received(data) {
        if (window.debug_received_log) {
          console.log("Received:", data);
        }
        let cbs = callbacks[data.action];
        if (typeof cbs !== "undefined") {
          for (let i = 0; i < cbs.length; i++) {
            cbs[i].call(this, data);
          }
        } else {
          console.log("Unhandled action", data);
        }
      },

      connected() {
        console.log("User devices channel connected.");
      },

      disconnected() {
        console.log("User devices channel disconnected");
      },

      request_bootup(options) {
        console.log("options", options);
        this.perform("request_bootup", { device_serial: options.device });
      },

      request_ac_stop(options) {
        console.log("options", options);
        this.perform("request_ac_stop", { device_serial: options.device, cabin: options.cabin, enable: options.enable });
      },

      request_hvac_toggle(options) {
        console.log("options", options);
        this.perform("request_hvac_toggle", { device_serial: options.device, cabin: options.cabin, enable: options.enable });
      },

      request_heating_toggle(options) {
        console.log("options", options);
        this.perform("request_heating_toggle", { device_serial: options.device, cabin: options.cabin, enable: options.enable });
      },

      request_cooling_toggle(options) {
        console.log("options", options);
        this.perform("request_cooling_toggle", { device_serial: options.device, cabin: options.cabin, enable: options.enable });
      },
    },
  );
  return UserDevicesChannel;
}

export { createUserDevicesChannel, UserDevicesChannel };
